import React, { useEffect, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import useQueryEnrollment from 'hooks/queries/useQueryEnrollment/useQueryEnrollment';
import Logo from 'components/atoms/Logo/Logo';
import LoadingIndicator from 'components/atoms/LoadingIndicator/LoadingIndicator';
import Button from '@mui/material/Button';
import useMutationConfirmEnrollment from 'hooks/mutations/useMutationConfirmEnrollment/useMutationConfirmEnrollment';
import useMutationDeclineEnrollment from 'hooks/mutations/useMutationDeclineEnrollment/useMutationDeclineEnrollment';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { EnrollmentStatus, ScheduleItemAvailability } from 'generated/graphql';
import PaymentSetup from 'pages/EnrollmentConfirm/components/PaymentSetup/PaymentSetup';
import { DateTime } from 'luxon';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { EnrollmentFromQuery } from 'types';
import ErrorAlert from 'components/molecules/ErrorAlert/ErrorAlert';

const dayOfWeekMap: Record<number, string> = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
};

function getScheduledDateText(dayOfWeek: string, enrollment: EnrollmentFromQuery) {
  let dayOfWeekText = `${dayOfWeek}s`;
  if (enrollment.scheduleInterval === 2) {
    dayOfWeekText = `Every other ${dayOfWeek}`;
  } else if (enrollment.scheduleInterval > 2) {
    dayOfWeekText = `Every ${enrollment.scheduleInterval} ${dayOfWeek}s`;
  }

  return `${dayOfWeekText} at ${
    enrollment.startTime ? DateTime.fromFormat(enrollment.startTime, 'TT').toLocaleString(DateTime.TIME_SIMPLE) : '?'
  } central time`;
}

const EnrollmentConfirmPage = () => {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [declined, setDeclined] = useState<boolean>(false);
  const [showDeclineStep, setShowDeclineStep] = useState<boolean>(false);
  const [declineReason, setDeclineReason] = useState<string>('');
  const [invalidStatus, setInvalidStatus] = useState<boolean>(false);
  const { logout, isAuthenticated } = useAuth0();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const token = params.get('token');
  const parsedId = id ? parseInt(id, 10) : undefined;

  const { data, loading, error } = useQueryEnrollment({
    variables: id ? { enrollmentId: parseInt(id, 10) } : undefined,
    context: {
      headers: {
        Authorization: token,
      },
    },
  });

  const [confirmEnrollment, { loading: confirming }] = useMutationConfirmEnrollment();
  const [declineEnrollment, { loading: declining }] = useMutationDeclineEnrollment();

  const handleDeclineReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeclineReason(event.target.value);
  };

  const handleConfirm = async () => {
    if (parsedId) {
      await confirmEnrollment({
        variables: {
          id: parsedId,
        },
      });
      setConfirmed(true);
    }
  };

  const handleDeclineFinalize = async () => {
    if (parsedId) {
      await declineEnrollment({
        variables: {
          input: {
            id: parsedId,
            reason: declineReason || undefined,
          },
        },
      });
      setDeclined(true);
    }
  };

  const handleDeclineInitialClick = () => {
    setShowDeclineStep(true);
  };

  const enrollment = data?.enrollment;

  function formatDates(dates: ScheduleItemAvailability[]) {
    return dates
      ?.map((exclusionDate) =>
        exclusionDate.startDate === exclusionDate.endDate
          ? `${moment(exclusionDate.startDate).format('MMM D')}`
          : `${moment(exclusionDate.startDate).format('MMM D')} - ${moment(exclusionDate.endDate).format('MMM DD')}`
      )
      .join(', ');
  }

  const { studentName, scheduledDate, instructorName, skillName, lessonType, status } = useMemo(() => {
    if (!enrollment) {
      return {};
    }

    const dayOfWeek = enrollment.dayOfWeek != null ? dayOfWeekMap[enrollment.dayOfWeek] : 'Unknown';
    const studentUser = enrollment.studentProfile.user;
    const studentName = `${studentUser?.firstName} ${studentUser?.lastName}`;
    const instructor = enrollment.scheduledInstructor || enrollment.preferredInstructor;
    const instructorUser = instructor?.user;
    const scheduledDate = getScheduledDateText(dayOfWeek, enrollment);
    const instructorName = `${instructorUser?.firstName} ${instructorUser?.lastName}`;
    const skillName = enrollment.skill?.skillName;
    const lessonType = enrollment.package?.name;
    const account = enrollment.studentProfile.account;

    return {
      dayOfWeek,
      studentName,
      scheduledDate,
      instructorName,
      skillName,
      lessonType,
      account,
      status: enrollment.schedulingStatus,
    };
  }, [enrollment]);

  // Based on the status, determine the current state to show.
  useEffect(() => {
    if (!status) {
      return;
    }

    if (status === EnrollmentStatus.UserConfirmed || status === EnrollmentStatus.Approved) {
      setConfirmed(true);
      return;
    }

    if (status === EnrollmentStatus.UserDeclined) {
      setDeclined(true);
      return;
    }

    if (status !== EnrollmentStatus.PendingConfirmation && status !== EnrollmentStatus.ReadyForConfirmation) {
      // This page is not supported yet for this status.
      setInvalidStatus(true);
    }
  }, [status]);

  // Log out, since the currently authenticated user is not necessarily
  // associated with the account.
  if (isAuthenticated) {
    logout({ logoutParams: { returnTo: window.location.href } });
  }

  const confirmationTokenMatches = true; //enrollment && enrollment.confirmationToken === token;

  return (
    <Container sx={{ py: 4 }}>
      <Stack direction='column' spacing={4} alignItems='center' justifyContent='center' sx={{ height: '100%' }}>
        <Logo size='large' />
        {!enrollment && error && (
          <Box sx={{ py: 4 }}>
            <ErrorAlert error={error.message} />
          </Box>
        )}
        {enrollment && !confirmationTokenMatches && (
          <Box sx={{ py: 4 }}>
            <Alert severity='error'>The confirmation token provided is invalid. Please contact support.</Alert>
          </Box>
        )}
        {invalidStatus && (
          <>
            <Typography variant='h1' color='black' align='center'>
              Lesson not ready for confirmation.
            </Typography>
          </>
        )}
        {confirmationTokenMatches && !invalidStatus && (
          <>
            <Typography variant='h1' color='black' align='center'>
              Confirm Lesson for <br />
              {studentName}
            </Typography>
            {loading && <LoadingIndicator />}
            {data?.enrollment && (
              <div>
                <Typography>
                  {data.enrollment.schedule.name}: {moment(data.enrollment.schedule.startDate).format('MMM D')} -{' '}
                  {moment(data.enrollment.schedule.endDate).format('MMM DD')}
                  <br />
                  {data.enrollment.schedule.exclusionDates && data.enrollment.schedule.exclusionDates.length > 0 && (
                    <>No Lessons: {formatDates(data.enrollment.schedule?.exclusionDates)}</>
                  )}
                  <br />
                  {data.enrollment.schedule.makeupDates && data.enrollment.schedule.makeupDates.length > 0 && (
                    <>Make-up Lessons: {formatDates(data.enrollment.schedule?.makeupDates)}</>
                  )}
                </Typography>
                <br />
                <Typography>
                  Date &amp; Time: {scheduledDate}
                  <br />
                  Type: {skillName} &mdash; {lessonType}
                  <br />
                  Teacher: {instructorName}
                </Typography>
                <Divider sx={{ my: 2 }} />
              </div>
            )}
          </>
        )}
        {confirmationTokenMatches && !confirmed && !declined && !invalidStatus && data?.enrollment && (
          <Stack direction='column' spacing={2}>
            {!showDeclineStep && (
              <Button variant='contained' onClick={handleConfirm} disabled={confirming || declining} fullWidth>
                {confirming ? 'Confirming...' : 'Confirm Lesson Time'}
              </Button>
            )}
            {showDeclineStep && (
              <div>
                <Typography gutterBottom>Are you sure you want to decline?</Typography>
                <TextField
                  id='decline-enrollment-reason'
                  label='Add notes (optional)'
                  multiline
                  maxRows={4}
                  value={declineReason}
                  onChange={handleDeclineReasonChange}
                  fullWidth
                />
              </div>
            )}
            <Button
              variant={showDeclineStep ? 'contained' : 'outlined'}
              onClick={showDeclineStep ? handleDeclineFinalize : handleDeclineInitialClick}
              disabled={confirming || declining}
              fullWidth
            >
              {declining ? 'Declining...' : showDeclineStep ? 'Yes, Decline' : 'Decline Time'}
            </Button>
            {showDeclineStep && (
              <Button
                variant='outlined'
                onClick={() => setShowDeclineStep(false)}
                disabled={confirming || declining}
                fullWidth
              >
                Never mind
              </Button>
            )}
          </Stack>
        )}
        {confirmed && enrollment && <PaymentSetup enrollment={enrollment} />}
        {declined && (
          <>
            <Typography variant='h1' color='black'>
              Lesson Time Declined
            </Typography>
            <Typography>We will reach out with next steps for scheduling your lesson for this semester!</Typography>
          </>
        )}
      </Stack>
    </Container>
  );
};

export default EnrollmentConfirmPage;
